import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import { Section, Container } from '../components/common';
import {
  GroupsHeader,
  Faq,
  IntroText,
  Gallery,
  Location
} from '../components/layout/index.js';
import useTranslations from '../components/layout/useTranslations';

import greg from '../images/final-1.png';

const groupPage = props => {
  const { data, location } = props;

  const {
    ourRooms,
    plan,
    formFill,
    bookWithCard,
    required,
    yourName,
    yourEmail,
    noShare,
    yourNumber,
    details,
    arrival,
    departure,
    paxNum,
    requirements,
    bookIt,
    phoneInstead,
    faq,
    enquire,
    groupFacilities,
    coordinatorBlurb,
    coordinatorTitle,
    knowHow,
    reasons
  } = useTranslations();

  const {
    featuredImage,
    title,
    intro,
    sellingPoints,
    seoMetaTags,
    hostel,
    menuDark,
    idealFor,
    body,
    priceFrom
  } = data.datoCmsGroup;

  const featureGal = hostel.featureGallery.map(photo => {
    Object.assign({
      srcSet: photo.fluid.srcSet,
      src: photo.fluid.src,
      caption: photo.caption,
      fluid: photo.fluid,
      alt: photo.alt
    });
    return photo;
  });

  const accomGal = data.datoCmsHostel.accommodationType.map(block => {
    const roomGals = block.roomGallery.map(photo =>
      Object.assign({
        srcSet: photo.fluid.srcSet,
        src: photo.fluid.src,
        caption: photo.caption,
        fluid: photo.fluid,
        alt: photo.alt
      })
    );
    return roomGals;
  });

  const combineAccom = [].concat.apply([], accomGal);

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />
      {/* Header section here */}
      <GroupsHeader
        backgroundImage={featuredImage.fluid}
        pageTitle={title}
        darken="true"
        menuDark={menuDark}
        link={data.datoCmsHostel.slug}
        propertyName={hostel.title}
        location={hostel.city}
        idealFor={idealFor}
        priceFrom={priceFrom}
        query={location.search}
        data={data.datoCmsGroup}
      />

      <IntroText reasons={reasons} text={intro} sellingPoints={sellingPoints} />

      <Section lightBackground>
        <Container>
          <h2>{ourRooms}</h2>
        </Container>
        <Container col="4" gap="1rem" mobcol="1fr 1fr">
          <Gallery images={combineAccom} />
        </Container>
      </Section>
      <Section>
        <Container col="2" mobcol="1fr" gap="2rem">
          <div>
            <h3>{knowHow}</h3>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
          <div>
            <img
              src={greg}
              style={{
                width: '100px',
                display: 'inline-block',
                float: 'left',
                margin: '20px 20px 0 0 '
              }}
            />
            <div>
              <h3 style={{ lineHeight: '1.8rem' }}>{coordinatorTitle}</h3>
              <p>{coordinatorBlurb}</p>
            </div>
          </div>
        </Container>
      </Section>
      <Section lightBackground>
        <Container>
          <h2>{groupFacilities}</h2>
          <p>
            With an excellent central {hostel.city} location our quality hostel
            offers comfy beds in a clean, modern and secure environment.
          </p>
        </Container>
        <Container col="4" gap="1rem" mobcol="1fr 1fr">
          <Gallery images={featureGal} />
        </Container>
      </Section>

      {/* Location section here */}
      <Location
        title={data.datoCmsHostel.title}
        streetAddress={data.datoCmsHostel.streetAddress}
        city={data.datoCmsHostel.city}
        latitude={data.datoCmsHostel.location.latitude}
        longitude={data.datoCmsHostel.location.longitude}
        mapScreenShot={data.datoCmsHostel.mapScreenShot.fluid}
        phone={data.datoCmsHostel.phone}
        emailAddress={data.datoCmsHostel.emailAddress}
        thingsNearBy={data.datoCmsHostel.thingsNearBy}
      />

      {/* FAQ */}
      <Section id="faq">
        <Container>
          <h2>{faq}</h2>
        </Container>
        <Container>
          <div>
            {data.datoCmsGroup.faq.map(block => (
              <div key={block.id} className={block.model.apiKey}>
                <Faq question={block.question} answer={block.answer} />
              </div>
            ))}
          </div>
        </Container>
      </Section>
    </>
  );
};

export default groupPage;

export const query = graphql`
  query GroupPageQuery($slug: String!, $hostel: String!, $locale: String!) {
    datoCmsGroup(locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      body
      intro
      priceFrom
      menuDark
      sellingPoints
      featureList
      groupsManagerName
      groupsManagerEmail
      groupsManagerHighriseId
      idealFor {
        idealForTag
        id
      }
      hostel {
        title
        city
        featureGallery {
          alt
          caption: title
          src: url
          fluid(
            maxWidth: 500
            imgixParams: {
              fm: "jpg"
              fit: "crop"
              crop: "faces"
              auto: "format"
              q: 50
            }
          ) {
            src
            srcSet
            ...GatsbyDatoCmsFluid
          }
        }
      }
      featuredImage {
        url
        fluid(maxWidth: 1000) {
          ...GatsbyDatoCmsFluid
        }
      }

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      faq {
        ... on DatoCmsQA {
          model {
            apiKey
          }
          id
          question
          answer
        }
      }
    }

    datoCmsHostel(locale: { eq: $locale }, slug: { eq: $hostel }) {
      title
      slug
      location {
        latitude
        longitude
      }
      streetAddress
      suburb

      city
      phone
      emailAddress
      thingsNearBy {
        id
        name
        time
        tripType
      }
      accommodationType {
        ... on DatoCmsAccom {
          model {
            apiKey
          }
          roomGallery {
            alt
            caption: title
            src: url
            fluid(
              maxWidth: 500
              imgixParams: {
                fm: "jpg"
                fit: "crop"
                crop: "faces"
                auto: "format"
                q: 50
              }
            ) {
              srcSet
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      mapScreenShot {
        fluid(
          maxWidth: 600
          imgixParams: { fm: "jpg", auto: "format", q: 50 }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
